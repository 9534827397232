<template>
  <div style="height: inherit">
    <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
      <!-- ECommerce Header -->
      <section id="ecommerce-header">
        <div class="row">
          <div class="col-sm-12 pt-1">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="21" @click="mqShallShowLeftSidebar = true" />
                <div class="search-results" v-if="filteredProductsCount > 0">{{ filteredProductsCount }} {{ $t('shop.result_found') }}</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Searchbar -->
      <div class="ecommerce-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input v-model="search" :placeholder="$t('shop.search_placeholder')" class="search-product" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- Prodcuts -->
      <section :class="productView" v-if="filteredProducts.length > 0">
        <b-card v-for="product in filteredProducts" :key="product.product_id" class="ecommerce-card" no-body>
          <div class="product-group-name">
            {{ product.product_group_name }}
          </div>

          <div class="product-image-div d-flex justify-content-center align-items-center">
            <div class="product-is-new bg-light-success rounded" v-if="product.is_new_product === 1" v-html="$t('shop.is_new')" />
            <b-img class="cursor-pointer product-image" :src="getProductImage(product)" @click="openProduct(product.product_id)" />
            <div v-if="product.brand_image_id" class="brand-image">
              <img :src="`https://${host}/Image/GetImage?image_id=${product.brand_image_id}`" :alt="`${product.brand_name} Photo`" />
            </div>
          </div>
          <!-- Product Details -->
          <b-card-body class="product-detail">
            <div class="d-flex">
              <div class="product-states">
                <a class="state bg-light-success rounded-circle" :href="whatsappHref(product)" target="_blank">
                  <i class="fab fa-whatsapp"></i>
                </a>
              </div>
            </div>
            <p class="product-name text-primary my-1" v-b-tooltip.hover.v-primary.top :title="product.product_name" @click="openProduct(product.product_id)">
              {{ product.product_name }}
            </p>
            <div class="product-properties">
              <div class="property-area">
                <p class="property">
                  <span class="property-type">
                    {{ $t('shop.product_code_tooltip') }}
                  </span>
                  <span class="property-value">
                    {{ product.product_code }}
                    <div class="cursor-pointer bg-light-primary rounded-circle" v-b-tooltip.hover.v-primary.html.top :title="compatibleBrandsTooltip(product.compatible_brands)">
                      <i class="far fa-info"></i>
                    </div>
                  </span>
                </p>
              </div>
              <div class="property-area">
                <p class="property">
                  <span class="property-type">
                    {{ $t('shop.orginal_code_tooltip') }}
                  </span>
                  <span class="property-value">
                    {{ product.orginal_code }}
                  </span>
                </p>
              </div>
              <div class="property-area">
                <p class="property">
                  <span class="property-type">
                    {{ $t('shop.brand') }}
                  </span>
                  <span class="property-value">
                    {{ product.brand_name }}
                  </span>
                </p>
              </div>
            </div>
          </b-card-body>

          <!-- Product Actions -->
          <div>
            <div class="item-actions-div">
              <template v-if="product.sales_unit_id !== product.tra_unit_id">
                <div></div>
                <div class="d-flex justify-content-center align-items-center pr-875">{{ getStockPieceByUnitID(product) }}</div>
              </template>
              <div class="d-flex">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-dropdown :text="$t(`unit.${product.tra_unit_id}`)" variant="outline-primary" right>
                      <b-dropdown-item
                        v-for="(unit, unitIndex) in product.units"
                        :key="`product_${product.product_id}_unit_${unitIndex}`"
                        @click="changeUnit(product, unit.unit2_id)"
                        :class="{
                          'bg-light-primary': unit.unit2_id === product.tra_unit_id
                        }"
                      >
                        {{ $t(`unit.${unit.unit2_id}`) }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-input-group-prepend>
                  <b-form-input :value="getUnitRateByID(product)" class="text-center h-100" disabled />
                </b-input-group>
              </div>
              <div v-if="checkInOfferList(product.product_id, product.tra_unit_id)" class="action-cart d-flex gap-2">
                <change-quantity idPrefix="grid_shop_item" :product="product" :productId="product.product_id" :unitId="product.tra_unit_id" :maxAmount="product.qty_avaible < product.qty_order_max ? product.qty_avaible : product.qty_order_max" />
                <b-button tag="a" variant="danger" @click="removeProductFromOfferList(product)" tabindex="-1">
                  <feather-icon icon="TrashIcon" size="20" />
                </b-button>
              </div>
              <div v-else class="d-flex gap-2">
                <b-input-group>
                  <cleave
                    :id="`card_shop_item_${product.product_id}`"
                    ref="input"
                    v-model="product.units.find(x => x.unit2_id === product.tra_unit_id).quantity"
                    :options="cleaveOptions"
                    class="form-control h-auto border-primary rounded rounded-r-0 text-center"
                    @keyup.native.enter="addOfferInList(product, $event)"
                  />
                  <b-input-group-append class="border-primary d-flex justify-content-center align-items-center px-0.5 rounded-r-1 bg-gray">
                    <span class="small">{{ $t(`unit.${product.tra_unit_id}`) }} </span>
                  </b-input-group-append>
                </b-input-group>

                <b-button variant="primary" tag="a" @click="handleOfferListActionClick(product)" :disabled="product.offerButtonState" tabindex="-1" v-b-tooltip.hover.v-primary.top :title="$t('offer.add_to_offer_list')">
                  <feather-icon icon="BriefcaseIcon" size="20" />
                </b-button>
              </div>
            </div>
          </div>
        </b-card>
      </section>
      <section v-else>
        <h3 class="mt-2">{{ $t('shop.not_found') }}</h3>
      </section>

      <!-- Pagination -->
      <section v-if="filteredProducts.length > 0">
        <b-row>
          <b-col cols="12">
            <b-pagination v-model="page" :total-rows="filteredProductsCount" :per-page="shopPerPage" first-number align="center" last-number prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>

      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-detached-left">
        <product-filters :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" />
      </portal>
    </b-overlay>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination, BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import ChangeQuantity from './ChangeQuantity.vue'
import ProductFilters from './ProductFilters.vue'
import { CHANGE_SHOP_PAGE_EVENT, GET_ALL_PRODUCTS, TYPE_SEARCH } from '@/store/services/shop-service'
import { mapGetters } from 'vuex'
import { ADD_OFFER, REMOVE_OFFER } from '@/store/services/offer-service'
import { SET_NOTIFICATION } from '@/store/services/notification-service'

export default {
  name: 'Shop',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BOverlay,

    // SFC
    ProductFilters,
    Cleave,
    ChangeQuantity
  },
  data() {
    return {
      productView: 'grid-view',
      ready: false,
      productViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' }
      ],
      mqShallShowLeftSidebar: false,
      host: process.env.VUE_APP_API_HOST
    }
  },
  created() {
    this.$store
      .dispatch(GET_ALL_PRODUCTS)
      .then(() => {
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1)
      })
      .then(() => {
        this.ready = true
      })
  },
  computed: {
    ...mapGetters(['filteredProducts', 'filteredProductsCount', 'shopSearch', 'shopPage', 'shopPerPage', 'productImages', 'getLocale', 'defaultProductImage', 'offerList', 'brandLogo']),
    search: {
      get() {
        return this.shopSearch
      },
      set(val) {
        this.$store.commit(TYPE_SEARCH, val)
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1)
      }
    },
    page: {
      get() {
        return this.shopPage
      },
      set(val) {
        const rootEle = document.documentElement
        rootEle.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, val)
      }
    },
    cleaveOptions() {
      switch (this.getLocale) {
        case 'tr-TR':
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
            numeralDecimalMark: ',',
            delimiter: '.'
          }
        default:
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
            numeralDecimalMark: '.',
            delimiter: ','
          }
      }
    }
  },
  methods: {
    compatibleBrandsTooltip(brands) {
      let html = `<table>
        <tr>
          <td>
            ${this.$t('shop.compatible_brands_tooltip')}
          </td>
        </tr>`
      brands.forEach(item => {
        html += `
        <tr>
          <td>${item}</td>
        </tr>
        `
      })
      html += `</table>`
      return html
    },
    openProduct(id) {
      let routeData = this.$router.resolve({
        name: 'ProductDetail',
        params: { id }
      })
      window.open(routeData.href, '_blank')
    },
    getProductImage(product) {
      let tempImages = JSON.parse(product.product_images ?? '[]')
      if (tempImages?.length) {
        return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${tempImages[0].image_id}`
      }
      return this.brandLogo
    },
    whatsappHref(product) {
      return `https://wa.me/905332084725?text=Merhaba, '${product.product_name}' ürünü hakkında bilgi almak istiyorum`
    },
    changeUnit(product, unit_id) {
      product.tra_unit_id = unit_id
    },
    getUnitRateByID(product) {
      let rate = product.units.find(x => x.unit2_id === product.tra_unit_id)?.rate ?? 1
      return `${rate} ${this.$t(`unit.${product.sales_unit_id}`)}`
    },
    getStockPieceByUnitID(product) {
      let productUnit = product.units.find(x => x.unit2_id === product.tra_unit_id)
      let rate = productUnit?.rate ?? 1
      let rate2 = productUnit?.rate2 ?? 1
      let productQuantity = productUnit?.quantity ?? 1

      let amount = (rate / rate2) * productQuantity
      return `${amount} ${this.$t(`unit.${product.sales_unit_id}`)}`
    },
    checkInOfferList(product_id, unit_id) {
      return this.offerList.findIndex(x => x.product_id === product_id && x.unit_id === unit_id) !== -1
    },
    handleOfferListActionClick(product) {
      let tempImages = JSON.parse(product.product_images ?? '[]')
      let image_id = undefined
      if (tempImages?.length) {
        image_id = tempImages[0].image_id
      }
      let quantity = product.units.find(x => x.unit2_id === product.tra_unit_id)?.quantity ?? 1
      this.$store.commit(ADD_OFFER, {
        product_id: product.product_id,
        unit_id: product.tra_unit_id,
        sales_unit_id: product.sales_unit_id,
        image_id,
        quantity: +quantity,
        brand_name: product.brand_name,
        product_name: product.product_name,
        product_code: product.product_code,
        brand_image_id: product.brand_image_id,
        currency_id: product.currency_id
      })
      this.$store.commit(SET_NOTIFICATION, {
        status: true,
        title: this.$t('offer.list'),
        message: this.$t('offer.add_offer_message')
      })
    },
    addOfferInList(product, event) {
      let tempImages = JSON.parse(product.product_images ?? '[]')
      let image_id = undefined
      if (tempImages?.length) {
        image_id = tempImages[0].image_id
      }
      let quantity = product.units.find(x => x.unit2_id === product.tra_unit_id)?.quantity ?? 1
      if (+quantity > 0) {
        this.$store.commit(ADD_OFFER, {
          product_id: product.product_id,
          unit_id: product.tra_unit_id,
          sales_unit_id: product.sales_unit_id,
          image_id,
          quantity: +quantity,
          brand_name: product.brand_name,
          product_name: product.product_name,
          product_code: product.product_code,
          brand_image_id: product.brand_image_id,
          currency_id: product.currency_id
        })
        this.$store.commit(SET_NOTIFICATION, {
          status: true,
          title: this.$t('offer.list'),
          message: this.$t('offer.add_offer_message')
        })
      }
    },
    removeProductFromOfferList(product) {
      this.$store.commit(REMOVE_OFFER, {
        product_id: product.product_id,
        unit_id: product.tra_unit_id
      })
      this.$store.commit(SET_NOTIFICATION, {
        status: true,
        title: this.$t('offer.list'),
        message: this.$t('offer.remove_offer_message')
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-ecommerce-details.scss';

.sidebar-shop {
  z-index: 1000 !important;
}

.item-name {
  padding-right: 5px;
}
</style>

<style lang="scss" scoped>
.swiper-navigations {
  width: 100%;
}

.product-image-div {
  min-height: 300px;
  position: relative;

  .brand-image {
    position: absolute;
    top: 1rem;
    left: 2rem;
    img {
      width: 75px;
      height: 50px;
    }
  }
}

.product-image {
  width: 300px !important;
  max-width: 300px !important;
  object-fit: cover;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.list-view {
  .product-detail {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
}

.item-actions-div {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 0.5rem;
  padding: 0.5rem;
}

.list-view {
  .item-actions-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;

    .action-wishlist,
    .action-cart {
      min-height: 50px;
      height: 100%;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 !important;
        min-height: 50px;
      }
    }
  }
}

.product-group-name {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px 5px 0 5px;
  text-transform: uppercase;
  opacity: 0.8;
  font-size: 12px;
  letter-spacing: 5px;
}

.product-is-new {
  display: flex;
  position: absolute;
  top: 0.8rem;
  left: -5.2rem;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  width: 200px;
  justify-content: center;
  transform: rotateZ(-45deg);
  font-size: 9px;
  line-height: 11px;
  font-weight: 900;
  padding: 3px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.product-detail {
  $font-size: 14px;

  .product-states {
    display: flex;
    gap: 8px;

    .state {
      width: 33px;
      height: 33px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .product-name {
    display: -webkit-box;
    font-size: $font-size + 1;
    line-height: $font-size + 5;
    width: 100%;
    height: ($font-size * 2) + 12;
    margin: 0;
    padding: 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .product-properties {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 4px;
    flex-direction: column;
    font-size: $font-size;

    .property-area {
      display: grid;
      grid-template-columns: 2fr 1fr;

      .property {
        margin: 0;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        flex-direction: column;

        .property-type {
          font-size: $font-size / 2;
          line-height: $font-size / 2;
          font-weight: 900;
          text-transform: uppercase;
          letter-spacing: 3px;
        }

        .property-value {
          padding: 0;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;

          div {
            height: 21px;
            width: 21px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
          }
        }

        .property-price {
          padding: 0;
          margin: 0;
          font-weight: 900;

          &.discount {
            font-size: $font-size - 2;
            font-weight: 500;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

.product-actions {
  height: 3rem;
  width: 14rem;
  margin-left: auto;
  margin-right: auto;
  .product-actions-quantity {
    height: 100%;
    display: flex;
    gap: 0.25rem;
    .form-control {
      text-align: center;
    }
  }

  .product-actions-not-avaible {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.pr-875 {
  padding-right: 8.75rem;
}
</style>
