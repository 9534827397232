<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">
              {{ $t("shop.filters") }}
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>
          <!-- Selected Filters -->
          <div v-show="selectedFilters.length > 0">
            <h6 class="filter-title">
              {{ $t("shop.selected_filters") }}
              <b-badge variant="light-warning">
                {{ selectedFilters.length }}
              </b-badge>
            </h6>
            <div class="selected-filters">
              <div class="filters">
                <div class="filter-template">
                  <div
                    v-for="filter in selectedFilters"
                    :key="filter.value"
                    :class="'filter bg-gradient-' + filter.class"
                  >
                    <div class="filter-detail">
                      <span>
                        {{ filter.type_text }}
                      </span>
                      <p>
                        {{ filter.text }}
                      </p>
                    </div>
                    <a
                      class="remove-button"
                      @click="removeFilter(filter.type, filter.value)"
                    >
                      <feather-icon icon="XIcon" />
                    </a>
                  </div>
                </div>
              </div>
              <a class="clear-filters" @click="clearFilters()">
                {{ $t("shop.clear_filters") }}
              </a>
            </div>
            <hr />
          </div>

          <!-- Product Groups -->
          <div>
            <h6
              class="filter-title cursor-pointer"
              @click="categoryShow = !categoryShow"
            >
              {{ $t("shop.group") }}
              <b-badge variant="light-warning" v-if="showedCategories > 0">
                {{ showedCategories }}
              </b-badge>
              <feather-icon
                :icon="categoryShow ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                size="20"
                class="float-right"
              />
            </h6>
            <b-input-group v-show="categoryShow">
              <b-form-input
                v-model="searchC"
                :placeholder="$t('shop.search_placeholder')"
                class="filter-search-input"
              />
            </b-input-group>
            <div class="filter-group" v-show="categoryShow">
              <b-form-checkbox-group
                v-model="category"
                stacked
                v-if="showedCategories > 0"
              >
                <div
                  class="category-div"
                  v-for="main in shopCategories"
                  :key="main.id"
                >
                  <div class="main" v-show="main.show">
                    <small>{{ main.name }}</small>
                    <b-badge variant="light-warning" class="ml-50">
                      {{ main.showedChild }}
                    </b-badge>
                  </div>
                  <hr v-show="main.show" />

                  <div
                    v-if="main.showedChild > 0"
                    class="categories-radio-group"
                  >
                    <b-form-checkbox
                      v-for="child in main.childrens"
                      :key="child.value"
                      :value="child.value"
                      v-show="child.show"
                    >
                      {{ child.text }}
                    </b-form-checkbox>
                  </div>
                </div>
              </b-form-checkbox-group>
              <span
                v-else
                class="d-flex justify-content-center align-items-center"
              >
                {{ $t("shop.not_found") }}
              </span>
            </div>
          </div>

          <hr />

          <!-- Brands -->
          <div>
            <h6
              class="filter-title cursor-pointer"
              @click="brandsShow = !brandsShow"
            >
              {{ $t("shop.brand") }}
              <b-badge variant="light-warning" v-if="showedBrands">
                {{ showedBrands }}
              </b-badge>
              <feather-icon
                :icon="brandsShow ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                size="20"
                class="float-right"
              />
            </h6>
            <b-input-group v-show="brandsShow">
              <b-form-input
                v-model="searchB"
                :placeholder="$t('shop.search_placeholder')"
                class="filter-search-input"
              />
            </b-input-group>
            <div class="filter-group" v-show="brandsShow">
              <b-form-checkbox-group
                v-model="brand"
                class="brands-checkbox-group"
                stacked
                v-if="showedBrands > 0"
              >
                <b-form-checkbox
                  v-for="brand in shopBrands"
                  :key="brand.value"
                  :value="brand.value"
                  v-show="brand.show"
                >
                  {{ brand.text }}
                </b-form-checkbox>
              </b-form-checkbox-group>
              <span
                v-else
                class="d-flex justify-content-center align-items-center"
              >
                {{ $t("shop.not_found") }}
              </span>
            </div>
          </div>
          <hr />
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormCheckboxGroup,
  BFormCheckbox,
  BLink,
  BInputGroup,
  BFormInput,
  BBadge,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import { mapGetters } from "vuex";
import {
  CHANGE_SHOP_PAGE_EVENT,
  CLEAR_SHOP_FILTERS,
  GET_ALL_BRAND,
  GET_ALL_PRODUCT_GROUP,
  SELECT_BRAND,
  SELECT_CATEGORY,
  TYPE_BRAND_SEARCH,
  TYPE_CATEGORY_SEARCH,
} from "@/store/services/shop-service";

export default {
  name: "ShopFilters",
  components: {
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormCheckbox,
    BLink,
    BCard,
    BInputGroup,
    BFormInput,
    BBadge,

    // 3rd Party
    VueSlider,
  },
  props: {
    mqShallShowLeftSidebar: {
      type: [Boolean, Object],
      required: true,
    },
    showActivePassive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      categoryShow: true,
      brandsShow: true,
    };
  },
  created() {
    this.$store.dispatch(GET_ALL_PRODUCT_GROUP);
    this.$store.dispatch(GET_ALL_BRAND);
  },
  computed: {
    ...mapGetters([
      "selectedCategory",
      "shopCategories",
      "categorySearch",
      "selectedBrand",
      "shopBrands",
      "brandSearch",
      "shopBrands",
    ]),
    category: {
      get() {
        return this.selectedCategory;
      },
      set(val) {
        this.$store.commit(SELECT_CATEGORY, val);
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1);
      },
    },
    brand: {
      get() {
        return this.selectedBrand;
      },
      set(val) {
        this.$store.commit(SELECT_BRAND, val);
        this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1);
      },
    },
    searchC: {
      get() {
        return this.categorySearch;
      },
      set(val) {
        this.$store.commit(TYPE_CATEGORY_SEARCH, val);
      },
    },
    searchB: {
      get() {
        return this.brandSearch;
      },
      set(val) {
        this.$store.commit(TYPE_BRAND_SEARCH, val);
      },
    },
    showedCategories() {
      let count = 0;
      this.shopCategories.forEach((item) => {
        if (item.show) {
          count += item.childrens.filter((x) => x.show).length + 1;
        }
      });
      return count;
    },
    showedBrands() {
      let count = 0;
      this.shopBrands.forEach((item) => {
        if (item.show) {
          count++;
        }
      });
      return count;
    },
    selectedFilters() {
      let filters = [];

      this.category.forEach((item) => {
        let text = this.shopCategories
          .find((x) => x.childrens.find((y) => y.value === item))
          .childrens.find((z) => z.value === item).text;

        filters.push({
          type: 1,
          type_text: this.$t("shop.group"),
          value: item,
          text,
          class: "primary",
        });
      });

      this.brand.forEach((item) => {
        let text = this.shopBrands.find((x) => x.value === item).text;
        filters.push({
          type: 2,
          type_text: this.$t("shop.brand"),
          value: item,
          text,
          class: "info",
        });
      });

      return filters;
    },
  },
  methods: {
    removeFilter(type, value) {
      let temp = [];
      let tempIndex = -1;
      switch (type) {
        case 1:
          temp = [...this.category];
          tempIndex = temp.findIndex((x) => x === value);
          temp.splice(tempIndex, 1);
          this.$store.commit(SELECT_CATEGORY, temp);
          break;
        case 2:
          temp = [...this.brand];
          tempIndex = temp.findIndex((x) => x === value);
          temp.splice(tempIndex, 1);
          this.$store.commit(SELECT_BRAND, temp);
          break;
      }
    },
    clearFilters() {
      this.$store.commit(CLEAR_SHOP_FILTERS);
    },
    resetPage() {
      this.$store.dispatch(CHANGE_SHOP_PAGE_EVENT, 1);
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
.custom-checkbox .custom-control-label {
  font-size: 0.8rem !important;
  &::before,
  &::after {
    height: 1rem;
    width: 1rem;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/include";
@import "vue-select/src/scss/global/_variables.scss";

.categories-radio-group,
.brands-checkbox-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}

.filter-group {
  max-height: 430px;
  overflow-y: scroll;
  padding: 1rem;
  font-size: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &::-webkit-scrollbar {
    width: 6px !important;
  }
}

.dark-layout {
  .filter-search-input {
    border-color: $theme-dark-border-color !important;
  }
}

.filter-search-input {
  border: none !important;
  border-bottom: 1px solid $border-color !important;
  border-radius: 0 !important;
}

.category-div {
  hr {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
.main {
  $font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-bottom: 4px;
  small {
    font-size: $font-size;
  }
  .badge {
    font-size: $font-size;
    line-height: $font-size;
  }
}

.selected-filters {
  //border: 1px solid $border-color;
  //border-radius: $border-radius;
  .filters {
    display: flex;
    flex-direction: column;
    max-height: 254px;
    padding: 1rem 0 0 1rem;
    .filter-template {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 8px;
      overflow-y: scroll;

      .filter {
        min-height: 40px;
        width: auto;
        border-radius: 0.358rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 13px;
        .filter-detail {
          display: flex;
          flex-direction: column;
          font-size: 10px;
          text-transform: uppercase;
          p {
            margin: 0;
            font-weight: bold;
          }
        }
        .remove-button {
          cursor: pointer;
          margin-left: 0.5rem;
        }
      }

      &::-webkit-scrollbar {
        width: 6px !important;
      }
    }
  }
  .clear-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-top: 1.5rem;
  }
}

.stock-switch {
  font-size: 11px;
  white-space: nowrap;
}
</style>
